import React, { useEffect, useState } from "react";
import { Input, Label, Card, CardBody, CardHeader, } from "reactstrap";
import { convertDate } from "../../Components/Common/helper";

const OrderInfo = (props) => {
    const [orderData, setOrderData] = useState([]);
    const [roll, setRoll] = useState([]);
    const { data, currentRoll, editRollToggle } = props;

    useEffect(() => {
        setOrderData(data);
    }, [data]);

    useEffect(() => {
        setRoll(currentRoll);
        // console.log("current roll: ", currentRoll)
        // console.log("roll: ", roll)
    }, [currentRoll]);

    return (
        <Card>
            <CardHeader>
                <h4 className="card-title">{props.t('orderDetail')}</h4>
            </CardHeader>
            <CardBody>
                <Label for="id">{props.t('partyNo')}</Label>
                <Input
                    type="text"
                    name="party_no"
                    id="party_no"
                    placeholder="Party No"
                    value={orderData && orderData.party_no}
                    style={{border: '1px solid #80808047', borderRadius: '3px', backgroundColor: '#cfcfcf73', marginBottom: '10px'}}
                    readOnly />

                <Label for="create_date">{props.t('createDate')}</Label>
                <Input
                    type="text"
                    name="created_at"
                    id="created_at"
                    placeholder={props.t('createDate')}
                    value={orderData && convertDate(orderData.created_at)}
                    style={{border: '1px solid #80808047', borderRadius: '3px', backgroundColor: '#cfcfcf73', marginBottom: '10px' }} 
                    readOnly/>


                <Label for="start_date">{props.t('startDate')}</Label>
                <Input
                    type="text"
                    name="started_at"
                    id="started_at"
                    placeholder={props.t('startDate')}
                    value={orderData && convertDate(orderData.started_at)}
                    style={{border: '1px solid #80808047', borderRadius: '3px', backgroundColor: '#cfcfcf73', marginBottom: '10px' }} 
                    readOnly/>

                <Label for="ended_at">{props.t('finishDate')}</Label>
                <Input
                    type="text"
                    name="ended_at"
                    id="ended_at"
                    placeholder={props.t('finishDate')}
                    value={orderData && convertDate(orderData.ended_at)}
                    style={{border: '1px solid #80808047', borderRadius: '3px', backgroundColor: '#cfcfcf73', marginBottom: '10px' }}
                    readOnly/>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Label for="finish_roll_meter">{props.t('finishRollMeter')}</Label>
                    {/* if roll.meter is not 0, display the icon */}
                    {roll && parseInt(roll.meter) !== 0 && (
                        <i className="bx bxs-edit bg-soft-success text-success fs-6 me-3 p-1"
                            style={{ cursor: 'pointer', border: '1px solid', borderRadius: '5px', marginBottom: '5px' }}
                            onClick={
                                (e) => {

                                    editRollToggle(e, roll.id);
                                }
                            }
                        ></i>
                    )}
                </div>
                <Input
                    type="text"
                    name="finish_roll_meter"
                    id="finish_roll_meter"
                    placeholder={"-"}
                    value={(roll && parseInt(roll.meter) !== 0) ? parseInt(roll.meter) : ''}
                    style={{ border: '1px solid #80808047', borderRadius: '3px', backgroundColor: '#cfcfcf73', marginBottom: '10px' }}
                    readOnly />
            </CardBody>
        </Card>
    );
}

export default OrderInfo;