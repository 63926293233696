import axios from "axios";
import { DecryptToken } from "../../api/helpers/decrypt";

const token = DecryptToken();

const ReportTypeDropdown = async () => {
    const api = new Promise((resolve, reject) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/report/dropdown/`,
            headers: {
                'Authorization': token
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    }
    );

    try {
        const response = await api;
        return response;
    }
    catch (error) {
        return error;
    }
};

const ExcelReport = async (filters) => {
    // filter flaws according to given filters
    function jsonToQueryString(json) {
        return Object.keys(json)
            .map(key => json[key] && `${key}=${json[key]}`)
            .join('&');
    }

    const params = filters && jsonToQueryString(filters);

    const url = `${process.env.REACT_APP_API_URL}/report/?${params}`

    const api = new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url: url,
            headers: {
                'Authorization': token
            },
            responseType: 'blob',
        })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });

    try {
        const response = await api;
        return response;
    }
    catch (error) {
        return error;
    };
};

const SummaryExcelReport = async (filters) => {
    // filter flaws according to given filters
    function jsonToQueryString(json) {
        return Object.keys(json)
            .map(key => json[key] && `${key}=${json[key]}`)
            .join('&');
    }

    const params = filters && jsonToQueryString(filters);

    const url = `${process.env.REACT_APP_API_URL}/report/summary/?${params}`

    const api = new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url: url,
            headers: {
                'Authorization': token
            },
            responseType: 'blob',
        })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });

    try {
        const response = await api;
        return response;
    }
    catch (error) {
        return error;
    };
};

const QualityExcelReport = async (filters) => {
    // filter flaws according to given filters
    function jsonToQueryString(json) {
        return Object.keys(json)
            .map(key => json[key] && `${key}=${json[key]}`)
            .join('&');
    }

    const params = filters && jsonToQueryString(filters);

    const url = `${process.env.REACT_APP_API_URL}/report/quality/?${params}`

    const api = new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url: url,
            headers: {
                'Authorization': token
            },
            responseType: 'blob',
        })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });

    try {
        const response = await api;
        return response;
    }
    catch (error) {
        return error;
    };
};


const CompanyExcelReport = async (filters) => {
    // filter flaws according to given filters
    function jsonToQueryString(json) {
        return Object.keys(json)
            .map(key => json[key] && `${key}=${json[key]}`)
            .join('&');
    }

    const params = filters && jsonToQueryString(filters);

    const url = `${process.env.REACT_APP_API_URL}/report/company/?${params}`

    const api = new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url: url,
            headers: {
                'Authorization': token
            },
            responseType: 'blob',
        })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });

    try {
        const response = await api;
        return response;
    }
    catch (error) {
        return error;
    };
};

// const DefaultExcelReport = async (orderId) => {
//     const url = `${process.env.REACT_APP_API_URL}/excel/${orderId}/default/`

//     const api = new Promise((resolve, reject) => {
//         axios({
//             method: 'GET',
//             url:  url,
//             headers: {
//                 'Authorization': token
//             },
//             responseType: 'blob',
//         })
//         .then((response) => {
//             resolve(response);
//         })
//         .catch((error) => {
//             reject(error);
//         });
//     });

//     try {
//         const response = await api;
//         return response;
//     }
//     catch (error) {
//         return error;
//     };
// }

const createFilterAPI = async (name, flaw_type_code, cycle_length, exclude_start, exclude_end, min_flaw_size, max_flaw_size) => {
    const api = new Promise((resolve, reject) => {
        try {
            axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_URL}/reportfilter/`,
                headers: {
                    Authorization: token
                },
                data: {
                    "name": name,
                    "flaw_type_code": flaw_type_code,
                    "cycle_length": cycle_length,
                    "exclude_start": exclude_start,
                    "exclude_end": exclude_end,
                    "min_flaw_size": min_flaw_size,
                    "max_flaw_size": max_flaw_size,
                }
            })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        }
        catch (error) {
            console.error(error);
        };
    });

    try {
        const response = await api; // Wait for the Promise to be resolved.
        return response
    } catch (error) {
        return {
            error: error
        }; // Handle the error appropriately or return a default value.
    }
}

async function getFilters(next){

    const url = next ? next : `${process.env.REACT_APP_API_URL}/reportfilter/`;
    const api = new Promise((resolve, reject) => {
        try{
            axios({
                method: 'get',
                url: url,
                headers: {
                    Authorization: token
                }
            }).then((response) => {
                resolve(response);
            }).catch((error) => {
                reject(error)
            });
        }
        catch (error){
            console.error(error);
        };
  });
  
    try {
        const response = await api; // Wait for the Promise to be resolved.
        return response
      } catch (error) {
        return {
            error: error.message
        }; // Handle the error appropriately or return a default value.
      }
  };

const removeFilterAPI = async (id) => {
    // delete a filter
    const api = new Promise((resolve, reject) => {
        try{
            axios({
                method: 'delete',
                url: `${process.env.REACT_APP_API_URL}/reportfilter/${id}/`,
                headers: {
                    Authorization: token
                }})
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
        }
        catch (error){
            console.error(error);
        };
    });

    try {
        const response = await api; // Wait for the Promise to be resolved.
        return response
    } 
    catch (error) {
        return {
            error: error
        }; // Handle the error appropriately or return a default value.
      }
};
export { ReportTypeDropdown, ExcelReport, SummaryExcelReport, QualityExcelReport, CompanyExcelReport, createFilterAPI, getFilters, removeFilterAPI };
