import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { searchParty } from '../../api/order/Order';
import { FlawTypeDropdown } from '../../api/flaw/Flaw';
import Select from 'react-select';


import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledAlert
} from 'reactstrap';
import classnames from "classnames";
import avatar1 from '../../assets/images/users/user.png';
import { GetUser } from '../../api/GetUser';
import {
  getCompanyPreference, updateDefectSizePreference,
  updateDefectFilterPreference, updateDefectPointPreference,
  updateReportPreference,
  updateMeterNotTakenPreference
} from '../../api/preference/Preference';
import { isEmptyArray } from 'formik';
import { useTranslation } from "react-i18next";
import AdminPanel from '../AdminPanel';

const FlawTypeFilter = (props) => {
  // Filter flaw types 
  const [options, setOptions] = useState([]);
  const [defaultValue, setDefaultValue] = useState();

  const fetchOptions = async () => {
    // Fetch Flaw Types from API
    const response = await FlawTypeDropdown();
    const data = response.result.map((item) => ({
      value: item[0],
      label: item[1]
    }));
    setOptions(data);
  }

  useEffect(() => {
    fetchOptions();
  }, []);

  // useEffect(() => {
  //   if (props.defaultValue) {
  //     setDefaultValue(props.defaultValue);
  //   }
  // }, [props.defaultValue]);

  return (
    <Select isMulti={props.isMulti} options={options}
      placeholder={props.t('flawTypePlaceHolder')}
      noOptionsMessage={() => { props.t('noResult') }}
      loadingMessage={() => { props.t('loading') }}
      value={
        props.flawType && Array.isArray(props.flawType) && props.flawType.length > 0
          ? options.filter(option => props.flawType.some(flaw => flaw.value === option.value))
          : null // Eğer `flawType` boşsa veya tanımlı değilse `null` yap
      }
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          borderColor: state.isFocused ? 'grey' : 'dodgerblue',
        }),
        menu: base => ({
          ...base,
          background: 'white',
          zIndex: 2
        })
      }}
      onChange={(e) => {
        const wrappedValue = Array.isArray(e) ? e : e ? [e] : [];
        props.setFlawType(wrappedValue && wrappedValue.length > 0 ? wrappedValue : null);
      }}
    />
  )
}


const PartyFilter = (props) => {
  // Filter party numbers
  const handleSearch = async (partyNo) => {
    // Search Party Number from API
    const response = await searchParty(partyNo);
    const data = response.data;
    return data;
  }

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(handleSearch(inputValue));
      }, 100);
    });


  return (
    <AsyncSelect cacheOptions defaultOptions
      isMulti loadOptions={promiseOptions}
      placeholder={props.t('partyNoPlaceHolder')}
      noOptionsMessage={({ inputValue }) =>
        inputValue ? props.t('noResult') : props.t('partyNoNotEntered')
      }
      loadingMessage={() => props.t('loading')}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          borderColor: state.isFocused ? 'grey' : 'dodgerblue',
        }),
        menu: base => ({
          ...base,
          background: 'white',
          zIndex: 2
        })
      }}
      onChange={props.setPartyNo}
    />
  )
};

const MinFlawSizeFilter = ({ t, flawType, minFlawTypeSize, setMinFlawTypeSize }) => {
  const handleChange = (index, type, value) => {
    const sanitizedValue = value.replace(/^0+/, '') || "0"; 
    setMinFlawTypeSize((prev) => {
      const flawKey = flawType[index].value;

      // If the value is "0" or empty string, set it to null
      const parsedValue = sanitizedValue && sanitizedValue !== "0" ? parseFloat(sanitizedValue) : null;

      // Create updated flaw object and clean null values
      const updatedFlaw = Object.fromEntries(
        Object.entries({ ...(prev?.[flawKey] || {}), [type]: parsedValue })
          .filter(([_, v]) => v !== null)
      );

      const remainingFlaws = { ...prev };
      if (Object.keys(updatedFlaw).length === 0) {
        delete remainingFlaws[flawKey]; // If it is completely empty remove it
      } else {
        remainingFlaws[flawKey] = updatedFlaw;
      }

      return Object.keys(remainingFlaws).length > 0 ? remainingFlaws : null;
    });
  };

  useEffect(() => {
    setMinFlawTypeSize((prev) => {
      if (!prev || !flawType || flawType.length === 0) return null; // If flawType is empty, clear it

      // Keep the ones in the new flawType list, remove the others
      const updatedSize = Object.fromEntries(
        Object.entries(prev || {}).filter(([key]) =>
          flawType.some((f) => f.value.toString() === key.toString())
        )
      );
      return updatedSize;
    });
  }, [flawType]);

  return (
    <div style={{ border: '1px solid dodgerblue', borderRadius: '4px', padding: '10px' }}>
      <Row>
        {!flawType || flawType.length === 0 ? (
          <p style={{ margin: 0, color: '#b50202' }}>{t('noFlawTypeSelected')}</p>
        ) : (
          flawType.map((type, index) => (
            <Col md={6} className="mb-3" key={index}>
              <div>
                <Label htmlFor={`defect_${index}`} className="form-label">
                  {type.label} | <span className="text-muted">mm</span>
                </Label>
                <div className="input-group input-group-sm">
                  <div className="form-floating">
                    <Input
                      type="number"
                      className="form-control"
                      id={`defect_${index}_width`}
                      style={{ border: '1px solid #ced4da' }}
                      step="1"
                      min="0"
                      placeholder={t('minWidth')}
                      value={minFlawTypeSize?.[type.value]?.min_width || ''}
                      onChange={(e) => handleChange(index, "min_width", e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "-" || e.key === "+" || e.key === "," || e.key === "." || e.key === "e") {
                          e.preventDefault(); // Block these characters from entering
                        }
                      }}
                    />
                    <Label htmlFor={`defect_${index}_width`}>{t('width')}</Label>
                  </div>
                  <div className="form-floating">
                    <Input
                      type="number"
                      className="form-control"
                      id={`defect_${index}_height`}
                      style={{ border: '1px solid #ced4da' }}
                      step="1"
                      min="0"
                      placeholder={t('minHeight')}
                      value={minFlawTypeSize?.[type.value]?.min_height || ''}
                      onChange={(e) => handleChange(index, "min_height", e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "-" || e.key === "+" || e.key === "," || e.key === "." || e.key === "e") {
                          e.preventDefault(); // Block these characters from entering
                        }
                      }}
                    />
                    <Label htmlFor={`defect_${index}_height`}>{t('height')}</Label>
                  </div>
                </div>
              </div>
            </Col>
          ))
        )}
      </Row>
    </div>
  )
};
const MaxFlawSizeFilter = (props) => {
  // Filter flaw type by maximum size
  const { t, flawType, maxFlawTypeSize, setMaxFlawTypeSize } = props;

  const handleChange = (index, type, value) => {
    console.log("valueeeee",value)

    const sanitizedValue = value.replace(/^0+/, '') || "0"; 

    setMaxFlawTypeSize((prev) => {
      const flawKey = flawType[index].value;

      // If the value is "0" or empty string, set it to null
      const parsedValue = sanitizedValue && sanitizedValue !== "0" ? parseFloat(sanitizedValue) : null;

      // Create updated flaw object and clean null values
      const updatedFlaw = Object.fromEntries(
        Object.entries({ ...(prev?.[flawKey] || {}), [type]: parsedValue })
          .filter(([_, v]) => v !== null)
      );

      const remainingFlaws = { ...prev };
      if (Object.keys(updatedFlaw).length === 0) {
        delete remainingFlaws[flawKey]; // If it is completely empty remove it
      } else {
        remainingFlaws[flawKey] = updatedFlaw;
      }

      return Object.keys(remainingFlaws).length > 0 ? remainingFlaws : null;
    });
  };

  useEffect(() => {
    setMaxFlawTypeSize((prev) => {
      if (!prev || !flawType || flawType.length === 0) return null; // If flawType is empty, clear it

      // Keep the ones in the new flawType list, remove the others
      const updatedSize = Object.fromEntries(
        Object.entries(prev || {}).filter(([key]) =>
          flawType.some((f) => f.value.toString() === key.toString())
        )
      );
      return updatedSize;
    });
  }, [flawType]);

  
  return (
    <div style={{ border: '1px solid dodgerblue', borderRadius: '4px', padding: '10px' }}>
      <Row>
        {!flawType || flawType.length === 0 ? (
          <p style={{ margin: 0, color: '#b50202' }}>{t('noFlawTypeSelected')}</p>
        ) : (
          flawType.map((type, index) => (
            <Col md={6} className="mb-3" key={index}>
              <div>
                <Label htmlFor={`defect_${index}`} className="form-label">
                  {type.label} | <span className="text-muted">mm</span>
                </Label>
                <div className="input-group input-group-sm">
                  <div className="form-floating">
                    <Input
                      type="number"
                      className="form-control"
                      id={`defect_${index}_width`}
                      style={{ border: '1px solid #ced4da' }}
                      step="1"
                      min="0"
                      placeholder={t('minWidth')}
                      value={maxFlawTypeSize?.[type.value]?.max_width || ''}
                      onChange={(e) => handleChange(index, "max_width", e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "-" || e.key === "+" || e.key === "," || e.key === "." || e.key === "e") {
                          e.preventDefault(); // Block these characters from entering
                        }
                      }}
                    />
                    <Label htmlFor={`defect_${index}_width`}>{t('width')}</Label>
                  </div>
                  <div className="form-floating">
                    <Input
                      type="number"
                      className="form-control"
                      id={`defect_${index}_height`}
                      style={{ border: '1px solid #ced4da' }}
                      step="1"
                      min="0"
                      placeholder={t('maxHeight')}
                      value={maxFlawTypeSize?.[type.value]?.max_height || ''}
                      onChange={(e) => handleChange(index, "max_height", e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "-" || e.key === "+" || e.key === "," || e.key === "." || e.key === "e") {
                          e.preventDefault(); // Block these characters from entering
                        }
                      }}
                    />
                    <Label htmlFor={`defect_${index}_height`}>{t('height')}</Label>
                  </div>
                </div>
              </div>
            </Col>
          ))
        )}
      </Row>
    </div>
  )
};

export { PartyFilter, FlawTypeFilter, MinFlawSizeFilter, MaxFlawSizeFilter };