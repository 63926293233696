import axios from "axios";
import { DecryptToken } from "../../api/helpers/decrypt";

const token = DecryptToken();


const CreateRoll = async (order_id) => {
    const api = new Promise((resolve, reject) => {
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/roll/`,
            headers: {
                'Authorization': token
            },
            data: {
                "order": order_id,
                "meter": 0
            }
        }).then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error);
        });
    }
    );

    try {
        const response = await api;
        return response;
    }
    catch (error) {
        return error;
    }
};

const getRoll = async (roll_id) => {
    /**
     * get roll
    */
    const api = new Promise((resolve, reject) => {
        try {
            axios({
                method: 'get',
                url: `${process.env.REACT_APP_API_URL}/roll/${roll_id}/`,
                headers: {
                    Authorization: token
                }
            }).then((response) => {
                resolve(response);
            }).catch((error) => {
                reject(error);
            });
        }
        catch (error) {
            // console.log(error);
        };
    });

    try {
        const response = await api;
        return response;
    }
    catch (error) {
        return error;
    };
};

const UpdateRoll = async (roll_id, meter) => {
    const api = new Promise((resolve, reject) => {
        axios({
            method: 'patch',
            url: `${process.env.REACT_APP_API_URL}/roll/${roll_id}/`,
            headers: {
                'Authorization': token
            },
            data: {
                "meter": meter,
                "status": false
            }
        }).then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error);
        });
    }
    );

    try {
        const response = await api;
        return response;
    }
    catch (error) {
        return error;
    }
};


const ActivateRoll = async (roll_id) => {
    const api = new Promise((resolve, reject) => {
        axios({
            method: 'patch',
            url: `${process.env.REACT_APP_API_URL}/roll/${roll_id}/`,
            headers: {
                'Authorization': token
            },
            data: {
                "status": true,
            }
        }).then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error);
        });
    }
    );

    try {
        const response = await api;
        return response;
    }
    catch (error) {
        return error;
    }
};


const DeactivateRoll = async (roll_id) => {
    const api = new Promise((resolve, reject) => {
        axios({
            method: 'patch',
            url: `${process.env.REACT_APP_API_URL}/roll/${roll_id}/`,
            headers: {
                'Authorization': token
            },
            data: {
                "status": false,
            }
        }).then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error);
        });
    }
    );

    try {
        const response = await api;
        return response;
    }
    catch (error) {
        return error;
    }
};


const GetRollFlaw = async (roll_id, url = null) => {
    /**
     * get roll's flaw
    */
    const next = url ? url : `${process.env.REACT_APP_API_URL}/roll/${roll_id}/flaws/`;
    const api = new Promise((resolve, reject) => {
        axios({
            method: 'get',
            url: next,
            headers: {
                'Authorization': token
            },
        }).then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error);
        });
    });

    try {
        const response = await api;
        return response;
    }
    catch (error) {
        return error;
    }
};

const DeleteRoll = async (roll_id) => {
    /*
        * Delete roll request to backend function
    */
    const api = new Promise((resolve, reject) => {
        axios({
            method: 'delete',
            url: `${process.env.REACT_APP_API_URL}/roll/${roll_id}/`,
            headers: {
                'Authorization': token
            }
        }).then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error);
        });
    });

    try {
        const response = await api;
        return response;
    }
    catch (error) {
        return error;
    };
};

const removeRequestRoll = async (roll_id) =>{
    /**
     * This function change roll deletion_request to true 
     * and it send to waiting list to admin
     */
    const api = new Promise((resolve, reject) => {
      try{
        axios({
          method: 'patch',
          url: `${process.env.REACT_APP_API_URL}/roll/${roll_id}/`,
          headers: {
            Authorization: token
          },
          data: {
            "deletion_request": true,
          }
        }).then((response) => {
          resolve(response);
        }).catch((error) => {
          reject(error)
        });
      }
      catch (error){
        // console.error(error);
      };
    });
  
    try {
      const response = await api; // Wait for the Promise to be resolved.
      return response
    }
    catch (error) {
      return error; 
    };
};

const GetPendingRolls = async () => {
    /**
     * This function return pending rolls
    */
    const api = new Promise((resolve, reject) => {
      try{
        axios({
          method: 'get',
          url: `${process.env.REACT_APP_API_URL}/roll/pending/`,
          headers: {
            Authorization: token
          },
        }).then((response) => {
          resolve(response);
        }).catch((error) => {
          reject(error)
        });
      }
      catch (error){
        console.error(error);
      };
    });
  
    try {
      const response = await api;
      return response;
    }
    catch (error) {
      return error;
    }
  };

const RejectDeleteRoll = async (roll_id) => {
    const api = new Promise((resolve, reject) => {
      try{
  
        axios({
          method: 'patch',
          url: `${process.env.REACT_APP_API_URL}/roll/${roll_id}/`,
          headers: {
            Authorization: token
          },
          data: {
            "deletion_request": false,
          }
        }).then((response) => {
          resolve(response);
        }).catch((error) => {
          reject(error);
        });
      }
      catch (error) {
        // console.log(error)
      }
    });
  
    try {
      const response = await api;
      return response;
    }
    catch (error) {
      return error;
    };
  };



export { CreateRoll, UpdateRoll, ActivateRoll, DeactivateRoll, GetRollFlaw, DeleteRoll, removeRequestRoll, GetPendingRolls, RejectDeleteRoll };
