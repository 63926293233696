import React, { useEffect, useMemo, useState } from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    Label,
    Modal, ModalBody, ModalHeader,
    Row,
    Input,
    Tooltip,
    FormGroup,
    FormText
} from 'reactstrap';
import { PartyFilter, FlawTypeFilter, MinFlawSizeFilter, MaxFlawSizeFilter } from './PartySearch';
import { ReportTypeFilter } from './ReportType';
import DatePickerMaterialUI from './DatePicker';
import { getFilteredFlawWithPartyNo, getFlawDetails, FlawTypeDropdown, getFilteredFlawWithPartyNoCompany } from "../../api/flaw/Flaw";
import ReportTable from "./ReportTable";
import { convertDate } from "../../Components/Common/helper";
import { ExcelReport, SummaryExcelReport, QualityExcelReport, CompanyExcelReport, createFilterAPI, getFilters, removeFilterAPI } from "../../api/report/Report";
import { useTranslation } from 'react-i18next';
import { Triangle } from "react-loader-spinner";
import LineChart from '../../Layout/charts/LineChart';
import { getFlawTypeCountRange } from '../../api/flawtype/FlawType';
import FilterTable from './FilterTable';

const ReportPanel = () => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [partyNo, setPartyNo] = useState(null);
    const [flawType, setFlawType] = useState(null);
    const [minFlawTypeSize, setMinFlawTypeSize] = useState(null);
    const [maxFlawTypeSize, setMaxFlawTypeSize] = useState(null);
    const [cyclicFlawMeter, setCyclicFlawMeter] = useState(null);
    const [excludeStart, setExcludeStart] = useState(null);
    const [excludeEnd, setExcludeEnd] = useState(null);
    const [flawTypeInfo, setFlawTypeInfo] = useState(null);
    const [flawDetail, setFlawDetail] = useState(null);
    const [modal, setModal] = useState(false);
    const [filterCreateModal, setFilterCreateModal] = useState(false);
    const [filterName, setFilterName] = useState('')
    const [filtersModal, setFiltersModal] = useState(false)
    const [filters, setFilters] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState(null);
    const [filterRemoveModal, setFilterRemoveModal] = useState(false)

    const [reportData, setReportData] = useState(null);
    const [clearTable, setClearTable] = useState(false);
    const [reportType, setReportType] = useState(null);
    const [flawRange, setFlawRange] = useState(null);

    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [info, setInfo] = useState(false);
    const [infoMsg, setInfoMsg] = useState("");
    const [success, setSuccess] = useState(false);
    const [successMsg, setSuccessMsg] = useState("");
    const [loader, setLoader] = useState(false);

    const { t } = useTranslation(['reportPanel']); // Using the 'reportPanel' namespace

    const loaderToogle = () => { setLoader(!loader) };
    const forceRemoveLoader = () => { setLoader(false) };

    const today = new Date();
    const formattedDate = `${String(today.getDate()).padStart(2, '0')}-${String(today.getMonth() + 1).padStart(2, '0')}-${today.getFullYear()}`;

    useEffect(() => {
        // get flaw type dropdown
        FlawTypeDropdown().then((response) => {
            setFlawTypeInfo(response.result);
        });
    }, []);

    useEffect(() => {
        if (flawTypeInfo) {
            const typeCode = sessionStorage.getItem('flaw_type_code')
            if (typeCode) {
                const params = { flaw_type_code: typeCode, limit: 7 };
                fetchFlaw(params)
            }
        }
    }, [flawTypeInfo]);

    useEffect(() => {
        setPartyNo(null)
        setFlawType(null)
        setMinFlawTypeSize(null)
        setMaxFlawTypeSize(null)
        setStartDate(null)
        setEndDate(null)
        setExcludeStart(null)
        setExcludeEnd(null)
        setFlawRange(null)
        setCyclicFlawMeter(null)
    }, [reportType]);

    useEffect(() => {
        setFlawRange(null)
    }, [startDate, endDate]);

    useEffect(() => {
        if (flawType === null) {
            setMinFlawTypeSize(null)
            setMaxFlawTypeSize(null)
        }
    }, [flawType]);


    const getFlawType = (flaw_type_id) => {
        // get flaw type name from flaw type id
        let flawType;
        flawTypeInfo.map((flaw) => {
            if (flaw[0] === flaw_type_id) {
                flawType = flaw[1];
            }
        });
        return flawType;
    };

    const fetchFlaw = async (params, url) => {
        // fetch filtered flaw from backend
        let response;
        if (url) {
            response = await getFilteredFlawWithPartyNo(undefined, url);
        }
        else {
            response = await getFilteredFlawWithPartyNo(params, undefined);
        }
        if (response.request.status === 200) {
            setReportData(response.data);

        };
    };

    const fetchFlawRange = async (params) => {

        // fetch filtered flaw from backend
        try {
            let response;
            const queryParams = new URLSearchParams(params).toString();
            response = await getFlawTypeCountRange(queryParams);
            setFlawRange(response);
        } catch (error) {
            console.error('HandleSubmit Error:', error);
        }
    };

    const fetchFlawCompany = async (params, url) => {
        // fetch filtered flaw from backend
        let response;
        if (url) {
            response = await getFilteredFlawWithPartyNoCompany(undefined, url);
        }
        else {
            response = await getFilteredFlawWithPartyNoCompany(params, undefined);
        }
        if (response.request.status === 200) {
            setReportData(response.data);

        };
    };

    const cleanFilter = (filter) => {
        if (!filter || Object.keys(filter).length === 0) {
            return null;
        }

        const cleanedFilter = Object.fromEntries(
            Object.entries(filter)
                .map(([key, value]) => {
                    // Filter values ​​that contain only 0
                    const cleanedValue = Object.fromEntries(
                        Object.entries(value).filter(([_, v]) => v !== 0) // Remove these
                    );

                    return [key, cleanedValue];
                })
                .filter(([_, v]) => Object.keys(v).length > 0) // If the object is completely empty, remove it too
        );

        return Object.keys(cleanedFilter).length > 0 ? cleanedFilter : null;
    };

    const getParams = () => {
        const params = {}
        params['order'] = partyNo && (partyNo.map((item) => parseInt(item.value))).join(',')
        params['flaw_type_code'] = flawType && (flawType.map((item) => parseInt(item.value))).join(',')
        params['created_at_after'] = startDate && startDate;
        params['created_at_before'] = endDate && endDate;
        params['cycle_length'] = cyclicFlawMeter && cyclicFlawMeter;
        params['exclude_start'] = excludeStart && excludeStart;
        params['exclude_end'] = excludeEnd && excludeEnd;
        params['limit'] = 7;

        const cleanedMinFlawSize = cleanFilter(minFlawTypeSize);
        const cleanedMaxFlawSize = cleanFilter(maxFlawTypeSize);

        if (cleanedMinFlawSize) {
            params['min_flaw_size'] = encodeURIComponent(JSON.stringify(cleanedMinFlawSize));
        }

        if (cleanedMaxFlawSize) {
            params['max_flaw_size'] = encodeURIComponent(JSON.stringify(cleanedMaxFlawSize));
        }
        return params;
    }

    const getParamsRange = () => {
        const params = {};
        params['flaw_type_code'] = flawType && flawType.map((item) => parseInt(item.value)).join(',');
        params['start_date'] = startDate && startDate.split('T')[0]; // Convert date format
        params['end_date'] = endDate && endDate.split('T')[0];       // Convert date format
        return params;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        // get filter parameters
        const params = getParams();
        const paramsRange = getParamsRange();
        setClearTable(true);
        setTimeout(() => {
            setClearTable(false);
        }, 1000)
        if (reportType?.value === 2) {
            if (flawType && startDate && endDate) {
                setLoader(true);
                try {
                    await fetchFlawRange(paramsRange);
                } catch (error) {
                    console.error("Error fetching flaws:", error);
                } finally {
                    setLoader(false);
                }
            } else {
                setErrorMsg(t('forGraphicalReportMsg'));
                setError(true);
                setTimeout(() => {
                    setError(false);
                }, 2000)
            }
        } else {
            if (reportType?.value === 4) {
                await fetchFlawCompany(params, undefined);
            } else {
                await fetchFlaw(params, undefined);
            }
        }
        sessionStorage.removeItem('flaw_type_code')  // remove flaw type code from session storage
    };

    const changePage = async (e, rotation) => {
        e.preventDefault();
        // change page according to income rotate (next, previous)
        const url = reportData[rotation]

        // fetch flaws
        await fetchFlaw(undefined, url);
    }

    const toggle = (flaw_id) => {
        // flaw detail modal toggle
        // when we close the modal, it returns event object
        // this cause to error in getFlawDetails function
        // so we check the type of flaw_id
        if (typeof (flaw_id) === 'number') {
            getFlawDetails(flaw_id).then((response) => {
                setFlawDetail(response.data);
                setModal(!modal);
            });
        }
        else {
            setModal(!modal);
        };
    };

    const getPartyNo = (orderId) => {
        // get party no by order id from state
        if (!partyNo) return orderId;
        const data = partyNo.filter((item) => (
            parseInt(item.value) === parseInt(orderId)
        ))
        return data.length > 0 ? data[0].label : orderId;
    };


    const searchTable = reportData && reportData.results.map((item) => {
        //console.log(item.image)
        const flaws = {
            "id": item.id,
            "image": item.image,
            "flaw_type": getFlawType(item.flaw_type_code),
            // "roll": item.roll,
            "order": item.party_no,
            "flaw_location": (item.millimeter) / 1000,
            "flaw_width": item.flaw_size.width,
            "flaw_height": item.flaw_size.height,
            "created_at": convertDate(item.created_at)
        }
        return flaws;
    });

    const columns = useMemo(() => [
        {
            Header: t('flawImage'),
            accessor: 'image',
            Cell: ({ cell: { value }, row: { original } }) => (
                <img
                    src={value}
                    alt="flaw_image"
                    loading='lazy'
                    style={{ maxWidth: '48px', maxHeight: '48px', height: '48px', cursor: 'pointer' }}
                    onClick={() => toggle(original.id)}
                />
            ),
        },
        {
            Header: t('flawType'),
            accessor: 'flaw_type',
            Cell: ({ cell: { value }, row: { original } }) => {
                return (
                    <span>{value}</span>
                )
            }
        },
        { Header: t('partyNo'), accessor: 'order' },
        // { Header: 'Top', accessor: 'roll' },
        { Header: t('flawPlace') + ' (' + t('shortMeter') + ')', accessor: 'flaw_location' },
        { Header: t('flawWidth') + ' (' + t('shortMillimeter') + ')', accessor: 'flaw_width' },
        { Header: t('flawHeight') + ' (' + t('shortMillimeter') + ')', accessor: 'flaw_height' },
        { Header: t('createdAt'), accessor: 'created_at' },
    ], [t]);

    const handleDateSelect = (e, func) => {
        /* when a datetime selected, convert this to ISO string
        params:
            e -> date data
            func -> state handler function
        */
        const date = new Date(e);
        const ISOformat = date.toISOString();
        func(ISOformat);
    }

    const handleExcelReport = async (e) => {
        e.preventDefault();

        // Assuming getParams() returns an object with keys and values
        const params = getParams();
        // change created_at_after as created_at

        // Remove 'limit' key and filter out null values
        const filteredParams = Object.fromEntries(
            Object.entries(params)
                .filter(([key, value]) => key !== 'limit' && value !== null)
        );

        try {
            loaderToogle();

            const response = await ExcelReport(filteredParams)
            // Create a Blob from the response data
            const blob = new Blob([response.data], {
                type: response.headers['content-type'], // Get the content type from response headers
            });

            // Create a link element
            const link = document.createElement('a');
            const url = window.URL.createObjectURL(blob);
            link.href = url;

            // Set the download attribute with a filename
            link.setAttribute('download', 'report.xlsx');

            // Append link to the body
            document.body.appendChild(link);

            // Trigger click event to start download
            link.click();

            // Remove the link element
            document.body.removeChild(link);

            // Release the blob URL
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading the Excel file:', error);
            setInfo(true);
            setInfoMsg(t('excelFlawMsg'));
            setTimeout(() => {
                setInfoMsg("");
                setInfo(false);
            }, 3000)
        } finally {
            forceRemoveLoader(); // Remove the loader when the download is finished or an error occurs
        }

        // console.log(param)

        // window.location.href=`${process.env.REACT_APP_API_URL}/report/?${param}`;
    }


    const handleSummaryExcelReport = async (e) => {
        e.preventDefault();

        // Assuming getParams() returns an object with keys and values
        const params = getParams();
        // change created_at_after as created_at

        // Remove 'limit' key and filter out null values
        const filteredParams = Object.fromEntries(
            Object.entries(params)
                .filter(([key, value]) => key !== 'limit' && value !== null)
        );

        try {
            loaderToogle();

            const response = await SummaryExcelReport(filteredParams)
            // Create a Blob from the response data
            const blob = new Blob([response.data], {
                type: response.headers['content-type'], // Get the content type from response headers
            });

            // Create a link element
            const link = document.createElement('a');
            const url = window.URL.createObjectURL(blob);
            link.href = url;

            // Set the download attribute with a filename
            link.setAttribute('download', 'summary_report.xlsx');

            // Append link to the body
            document.body.appendChild(link);

            // Trigger click event to start download
            link.click();

            // Remove the link element
            document.body.removeChild(link);

            // Release the blob URL
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading the Excel file:', error);
            setInfo(true);
            setInfoMsg(t('excelFlawMsg'));
            setTimeout(() => {
                setInfoMsg("");
                setInfo(false);
            }, 3000)
        } finally {
            forceRemoveLoader(); // Remove the loader when the download is finished or an error occurs
        }
    }

    const handleQualityExcelReport = async (e) => {
        e.preventDefault();

        // Assuming getParams() returns an object with keys and values
        const params = getParams();
        // change created_at_after as created_at

        // Remove 'limit' key and filter out null values
        const filteredParams = Object.fromEntries(
            Object.entries(params)
                .filter(([key, value]) => key !== 'limit' && value !== null)
        );

        try {
            loaderToogle();

            const response = await QualityExcelReport(filteredParams)
            // Create a Blob from the response data
            const blob = new Blob([response.data], {
                type: response.headers['content-type'], // Get the content type from response headers
            });

            // Create a link element
            const link = document.createElement('a');
            const url = window.URL.createObjectURL(blob);
            link.href = url;

            // Set the download attribute with a filename
            link.setAttribute('download', 'order_quality_report.xlsx');

            // Append link to the body
            document.body.appendChild(link);

            // Trigger click event to start download
            link.click();

            // Remove the link element
            document.body.removeChild(link);

            // Release the blob URL
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading the Excel file:', error);
            setInfo(true);
            setInfoMsg(t('excelFlawMsg'));
            setTimeout(() => {
                setInfoMsg("");
                setInfo(false);
            }, 3000)
        } finally {
            forceRemoveLoader(); // Remove the loader when the download is finished or an error occurs
        }
    }

    const handleCompanyExcelReport = async (e) => {
        e.preventDefault();

        // Assuming getParams() returns an object with keys and values
        const params = getParams();
        // change created_at_after as created_at

        // Remove 'limit' key and filter out null values
        const filteredParams = Object.fromEntries(
            Object.entries(params)
                .filter(([key, value]) => key !== 'limit' && value !== null)
        );

        try {
            loaderToogle();

            const response = await CompanyExcelReport(filteredParams)
            // Create a Blob from the response data
            const blob = new Blob([response.data], {
                type: response.headers['content-type'], // Get the content type from response headers
            });

            // Create a link element
            const link = document.createElement('a');
            const url = window.URL.createObjectURL(blob);
            link.href = url;

            const fileName = `company_report_${formattedDate}.xlsx`;

            // Set the download attribute with a filename
            link.setAttribute('download', fileName);

            // Append link to the body
            document.body.appendChild(link);

            // Trigger click event to start download
            link.click();

            // Remove the link element
            document.body.removeChild(link);

            // Release the blob URL
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading the Excel file:', error);
            setInfo(true);
            setInfoMsg(t('excelFlawMsg'));
            setTimeout(() => {
                setInfoMsg("");
                setInfo(false);
            }, 3000)
        } finally {
            forceRemoveLoader(); // Remove the loader when the download is finished or an error occurs
        }
    }


    const handleReport = async (e) => {
        e.preventDefault();
        if (reportType && reportType !== null) {
            // Control 'Summary Report', 'General Report' and 'Quality Report'
            const isCompanySelected = reportType.value === 4;
            const isQualitySelected = reportType.value === 3;
            const isSummarySelected = reportType.value === 1;
            const isGeneralSelected = reportType.value === 0;

            if (isQualitySelected || ((Array.isArray(partyNo) && partyNo.length > 0) || (startDate && endDate))) {
                // Control 'Summary Report' and 'General Report'

                if (isSummarySelected) {
                    await handleSummaryExcelReport(e); //
                } else if (isGeneralSelected) {
                    await handleExcelReport(e);
                } else if (isQualitySelected) {
                    await handleQualityExcelReport(e);
                } else if (isCompanySelected) {
                    await handleCompanyExcelReport(e);
                }

            } else {
                setErrorMsg(t('forExcelReportMsg'));
                setError(true);
                setTimeout(() => {
                    setError(false);
                }, 2000)

            }
        } else {
            setErrorMsg(t('reportChooseMsg'));
            setError(true);
            setTimeout(() => {
                setError(false);
            }, 2000)
        }
    };

    const InfoTooltip = ({ id, tooltipText }) => {
        const [tooltipOpen, setTooltipOpen] = useState(false);
        const [hover, setHover] = useState(false); // Hover situation

        const toggle = () => setTooltipOpen(!tooltipOpen);

        return (
            <div className="d-inline-block mx-2">
                {/* Question Icon */}
                <i
                    id={id}
                    className="ri-question-fill"
                    style={{
                        fontSize: "18px",
                        cursor: "pointer",
                        color: hover ? "#333" : "#bbb",
                        transition: "color 0.2s ease-in-out",
                    }}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                ></i>

                {/* Tooltip */}
                <Tooltip
                    placement="right"
                    isOpen={tooltipOpen}
                    target={id}
                    toggle={toggle}
                    fade={false}
                    transition={{ timeout: 0 }}
                >
                    {tooltipText}
                </Tooltip>
            </div>
        );
    };

    const toggleCreateFilter = () => {
        setFilterCreateModal(!filterCreateModal);
    };

    const toggleFilters = () => {
        fetchFilter()
        setFiltersModal(!filtersModal);
    };

    const toggleRemoveFilter = () => {
        /** 
         * when toggle open first clear previous machine id 
         * and then get new machine id & set new
        */
        setFilterRemoveModal(!filterRemoveModal);
    };

    const handleCreateFilterSubmit = async (e) => {
        e.preventDefault();

        const input = e.target[0].value;

        const flawTypeCodes = flawType && (flawType.map((item) => parseInt(item.value))).join(',')

        // if name is not null and name is not exist close the modal
        if (filterName !== '') {
            const response = await createFilterAPI(input, flawTypeCodes, cyclicFlawMeter, excludeStart, excludeEnd, minFlawTypeSize, maxFlawTypeSize);

            try {
                const statusCode = response.request.status;
                if (statusCode === 201) {
                    setSuccess(true);
                    setSuccessMsg(t('createFilterSuccessMsg'));
                    setTimeout(() => {
                        setSuccessMsg("");
                        setSuccess(false);
                    }, 3000)
                }
            }
            catch (err) {
                // console.log(err);
                setError(true);
                setErrorMsg(t('filterErrorMsg'));
                setTimeout(() => {
                    setErrorMsg("");
                    setError(false);
                }, 3000)
            }
            finally {
                setFilterName('');
                toggleCreateFilter();
            };
        };
    };

    const fetchFilter = async (url) => {
        /**
         * This function fetch filters data
         * and set data to state
        */
        const response = await getFilters(url);
        if (response.request.status === 200) {
            setFilters(response.data);
        }
    };

    const handleChooseFilter = (event) => {
        event.preventDefault();
        if (selectedFilter) {
            const matchedFilter = filters.results.find(filter => filter.id === selectedFilter);

            const flawTypeCodes = matchedFilter.flaw_type_code
                ? matchedFilter.flaw_type_code.split(",").map(code => code.trim())
                : null;

            const flawTypeArray = flawTypeCodes
                ? flawTypeCodes.map(code => {
                    const matchedFlawType = flawTypeInfo.find(flawType => flawType[0] == code);
                    return matchedFlawType ? { value: Number(matchedFlawType[0]), label: matchedFlawType[1] } : null;
                }).filter(item => item !== null)
                : null;

            setFlawType(flawTypeArray);

            setCyclicFlawMeter(matchedFilter.cycle_length)
            setExcludeStart(matchedFilter.exclude_start)
            setExcludeEnd(matchedFilter.exclude_end)
            setMinFlawTypeSize(matchedFilter.min_flaw_size)
            setMaxFlawTypeSize(matchedFilter.max_flaw_size)

            toggleFilters()
        } else {
            setFiltersModal(!filtersModal);
        }
    };

    const handleDeleteFilter = async (e) => {
        e.preventDefault();
        if (selectedFilter) {
            const response = await removeFilterAPI(selectedFilter);
            try {
                // for the succes remove
                if (response.status === 204) {
                    setSuccess(true);
                    setSuccessMsg(t('deleteFilterSuccessMsg'));
                    setTimeout(() => {
                        setSuccessMsg("");
                        setSuccess(false);
                    }, 3000)
                    setTimeout(() => {
                        setSelectedFilter(null)
                        fetchFilter();
                    }, 100)
                    setFilterRemoveModal(false)
                }
            }
            catch (err) {
                setError(true);
                setErrorMsg(t('filterErrorMsg'));
                setSelectedFilter(null)
                setTimeout(() => {
                    setErrorMsg("");
                    setError(false);
                }, 3000)
            }
        }
        else {
            console.log('Seçili filtre bulunamadı!');
        }
    };



    return (
        <div className="page-content ">
            <Container fluid>
                {/* LOADER */}
                <div className="loader"
                    style={{
                        background: '#a5a5a5cf', position: 'absolute',
                        top: '0', right: '0', left: '0', bottom: '0',
                        zIndex: '1000000', display: loader ? 'block' : 'none',
                        overflow: 'hidden'
                    }}>
                    <div className="loader-animation" style={{ position: 'relative', top: '50%', left: '50%', color: '#00425a' }}>
                        <Triangle
                            height={64}
                            width={64}
                            color={'#00425a'}
                            ariaLabel="triangle-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                        />
                    </div>
                    <p style={{ position: 'relative', top: '50%', left: '50%', translate: '-20px', transform: 'translateY(10px)', color: '#00425a' }}>
                        {(reportType?.value === 2) ? t('graphicalComparisonReportPreparing') : t('excelReportDownloading')}
                    </p>
                </div>

                {/* ALERTS */}
                {error && (
                    <div className="alert alert-danger alert-dismissible fade show text-center" role="alert">
                        <strong >{errorMsg && errorMsg}</strong>
                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setError(false)}></button>
                    </div>
                )}
                {info && (
                    <div className="alert alert-info alert-dismissible fade show text-center fixed-bottom m-3" role="alert">
                        <strong>{infoMsg && infoMsg}</strong>
                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setInfo(false)}></button>
                    </div>
                )}
                {success && (
                    <div className="alert alert-success alert-dismissible fade show text-center" role="alert">
                        <strong >{successMsg && successMsg}</strong>
                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setSuccess(false)}></button>
                    </div>
                )}

                {/* MODALS */}
                <Modal isOpen={modal}
                    toggle={toggle}
                    centered={true}
                    size={"xl"}>
                    <ModalHeader toggle={toggle} className="px-5 ms-3 text-uppercase">
                        <h4>{t('flawDetails')}</h4>
                    </ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-md-6" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                                {flawDetail && flawDetail.flaw_coordinate &&
                                    <div >
                                        <img id={`flaw_image_${flawDetail.id}`}
                                            src={modal && `${flawDetail.image}`} alt="flaw_image"
                                            loading="lazy"
                                            style={{
                                                width: '100%',
                                                maxWidth: '100%',
                                                borderRadius: '3px'
                                            }}
                                        />
                                    </div>
                                }

                            </div>
                            <div className="col-md-6 text-center mt-4 fs-5">
                                <div className="row pt-3">
                                    <div className="col-md-6 text-start">
                                        <p className='fs-5'><b>{t('flawType')}:</b></p>
                                    </div>
                                    <div className="col-md-6 text-start">
                                        <p className='fw-bold fs-5'>{flawDetail && getFlawType(flawDetail.flaw_type_code)}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 text-start">
                                        <p className='fs-5'><b>{t('flawWidth')}:</b></p>
                                    </div>
                                    <div className="col-md-6 text-start">
                                        <p className='fw-bold'>{modal && flawDetail.flaw_size.width} <span className='text-muted'>{t('millimeter')}</span></p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 text-start">
                                        <p className='fs-5'><b>{t('flawHeight')}:</b></p>
                                    </div>
                                    <div className="col-md-6 text-start">
                                        <p className='fw-bold'>{modal && flawDetail.flaw_size.height} <span className='text-muted'>{t('millimeter')}</span></p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 text-start">
                                        <p className='fs-5'><b>{t('flawPlace')}:</b></p>
                                    </div>
                                    <div className="col-md-6 text-start">
                                        <p className='fw-bold'>{modal && flawDetail.millimeter / 1000} <span className='text-muted'>{t('meter')}</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>

                {/* Create Filter Modal */}
                <Modal isOpen={filterCreateModal} toggle={toggleCreateFilter} centered={true}>
                    {!flawType &&
                        !cyclicFlawMeter &&
                        !excludeStart &&
                        !excludeEnd &&
                        !minFlawTypeSize &&
                        !maxFlawTypeSize ?
                        <div>
                            <ModalHeader style={{ color: 'rgb(182, 9, 9)' }} toggle={toggleCreateFilter}>{t('notice')} </ModalHeader>
                            <ModalBody>{t('createFilterNoticeMsg')}</ModalBody>
                        </div> :
                        <div>
                            <ModalHeader toggle={toggleCreateFilter} >{t('filterDetails')}</ModalHeader>
                            <ModalBody>
                                <Form onSubmit={handleCreateFilterSubmit}>
                                    <FormGroup>
                                        <Label for="filter_name">
                                            {t('filterName')}
                                        </Label>
                                        <Input
                                            type="text"
                                            name="filter_name"
                                            id="filter_name"
                                            placeholder={t('enterFilterName')}
                                            style={{ border: '1px solid #80808047', borderRadius: '3px' }}
                                            onChange={(e) => setFilterName(e)}
                                            invalid={(filterName === '')}
                                        />
                                    </FormGroup>
                                    <Button color="primary" type="submit">
                                        {t('save')}
                                    </Button>
                                </Form>
                            </ModalBody>
                        </div>
                    }
                </Modal>


                {/* Filters Modal */}
                <Modal isOpen={filtersModal} toggle={toggleFilters} centered={true} size={"xl"}>
                    <ModalHeader toggle={toggleFilters}>{t('myFilters')}</ModalHeader>
                    <ModalBody>
                        <Form onSubmit={handleChooseFilter}>
                            <div className="d-flex justify-content-end">
                                <Button
                                    className="btn-sm"
                                    style={{
                                        position: 'relative',
                                        right: '20px', top: '0',
                                        bottom: '0', width: '10%',
                                        maxWidth: '10%',
                                        margin: '0', backgroundColor: '#6e1423',
                                        border: '1px solid #641220',
                                        alignItems: 'center', textAlign: 'center'
                                    }}
                                    onClick={() => {
                                        if (selectedFilter) {
                                            setFilterRemoveModal(true);
                                        }
                                    }}
                                >
                                    {t('deleteFilter')}
                                </Button>
                            </div>
                            <FormGroup>
                                <FilterTable
                                    filterData={filters}
                                    flawTypeInfo={flawTypeInfo}
                                    selectedRow={selectedFilter}
                                    setSelectedRow={setSelectedFilter}
                                    fetchFilter={fetchFilter}
                                    t={t}
                                />
                            </FormGroup>
                            <div className="d-flex justify-content-end">
                                <Button color="primary" type="submit" style={{ right: '20px' }}>
                                    {t('choose')}
                                </Button>
                            </div>
                        </Form>
                    </ModalBody>
                </Modal>

                {/* Delete Filter Modal */}
                <Modal isOpen={filterRemoveModal} toggle={toggleRemoveFilter} centered={true}>
                    <ModalHeader toggle={toggleRemoveFilter} >{t('deleteFilter')}</ModalHeader>
                    <ModalBody>
                        <Form onSubmit={handleDeleteFilter}>
                            <FormGroup>
                                <FormText>
                                    {t('deleteFilterQuestion')}
                                </FormText>
                            </FormGroup>
                            <Button color="danger" type="submit">
                                {t('delete')}
                            </Button>
                        </Form>
                    </ModalBody>
                </Modal>



                <Row>
                    <Col lg="3">
                        <Card>
                            <CardHeader className='pb-0'>
                                <h4 className="card-title mb-0">{t('filters')}</h4>
                            </CardHeader>
                            <hr className="mb-0"></hr>
                            <CardBody>
                                <Form onSubmit={handleSubmit}>
                                    {/* REPORT TYPE FILTER SELECT */}
                                    <div className='d-flex justify-content-between align-items-end'>
                                        <div className="d-flex align-items-center">
                                            <Label className="mb-0" htmlFor="report_filter">{t('reportType')}</Label>
                                            <InfoTooltip id="tooltip1" tooltipText={t('reportTypeInfo')} />
                                        </div>
                                        {(reportType?.value === 4) ?
                                            <Button className='btn-sm mb-3' color='gray'
                                                onClick={toggleCreateFilter}
                                            >{t('saveFilter')}</Button>
                                            :
                                            <div />
                                        }

                                    </div>
                                    <ReportTypeFilter
                                        id="report_filter"
                                        setReportType={setReportType} t={t}
                                    // defaultValue={paramsData && paramsData}
                                    />
                                    {/* PARTY FILTER SELECT */}
                                    {(reportType?.value === 2 || reportType?.value === 3) ?
                                        <div />
                                        :
                                        <div className='mt-3'>
                                            <div className="d-flex align-items-center">
                                                <Label className="mb-0" htmlFor="party_filter">{t('partyNo')}</Label>
                                                <InfoTooltip id="tooltip2" tooltipText={t('partyNoInfo')} />
                                            </div>
                                            <PartyFilter id="part_filter" setPartyNo={setPartyNo} t={t} key={reportType?.value}
                                            />
                                        </div>
                                    }
                                    {(reportType?.value === 4) ?
                                        <Button className='w-100 mt-3 mb-0' color='gray' type='button'
                                            onClick={toggleFilters}>{t('myFilters')}</Button>
                                        :
                                        <div />
                                    }
                                    {/* FLAW TYPE FILTER SELECT */}
                                    {(reportType?.value === 3) ?
                                        <div />
                                        :
                                        <div className='mt-3'>
                                            <div className="d-flex align-items-center">
                                                <Label className="mb-0" htmlFor="flaw_filter">{t('flawType')}</Label>
                                                <InfoTooltip id="tooltip3" tooltipText={t('flawTypeInfo')} />
                                            </div>
                                            <FlawTypeFilter
                                                id="flaw_filter"
                                                flawType={flawType}
                                                setFlawType={setFlawType} t={t}
                                                isMulti={(reportType?.value === 2) ? false : true}
                                                key={reportType?.value}
                                            // defaultValue={paramsData && paramsData}
                                            />
                                        </div>
                                    }
                                    {/* MIN MAX FILTER*/}
                                    {((reportType?.value === 4) && flawType) ?
                                        <div>
                                            <div className='mt-3'>
                                                <div className='d-flex justify-content-between align-items-end'>
                                                    <div className="d-flex align-items-center">
                                                        <Label className="mb-0" htmlFor="flaw_type_min_filter">{t('minFlawTypeSize')}</Label>
                                                        <InfoTooltip id="tooltip4" tooltipText={t('minFlawTypeSizeInfo')} />
                                                    </div>
                                                    <Button className='btn-sm mb-1' color='primary'
                                                        onClick={() => {
                                                            setMinFlawTypeSize(null);  // clear min flaw type size
                                                        }}
                                                    >{t('clear')}</Button>
                                                </div>
                                                <MinFlawSizeFilter id="flaw_type_min_filter"
                                                    minFlawTypeSize={minFlawTypeSize}
                                                    setMinFlawTypeSize={setMinFlawTypeSize}
                                                    flawType={flawType} t={t}
                                                    key={flawType?.value} />
                                            </div>
                                            <div className='mt-3'>
                                                <div className='d-flex justify-content-between align-items-end'>
                                                    <div className="d-flex align-items-center">
                                                        <Label className="mb-0" htmlFor="flaw_type_max_filter">{t('maxFlawTypeSize')}</Label>
                                                        <InfoTooltip id="tooltip5" tooltipText={t('maxFlawTypeSizeInfo')} />
                                                    </div>
                                                    <Button className='btn-sm mb-1' color='primary'
                                                        onClick={() => {
                                                            setMaxFlawTypeSize(null);  // clear max flaw type size
                                                        }}
                                                    >{t('clear')}</Button>
                                                </div>
                                                <MaxFlawSizeFilter id="flaw_type_max_filter"
                                                    maxFlawTypeSize={maxFlawTypeSize}
                                                    setMaxFlawTypeSize={setMaxFlawTypeSize}
                                                    flawType={flawType} t={t}
                                                    key={flawType?.value} />
                                            </div>
                                        </div>
                                        : <div />

                                    }

                                    {/* EXCLUDE METER FILTER */}
                                    {(reportType?.value === 4) ?
                                        <div className='mt-3'>
                                            <div className="d-flex align-items-center">
                                                <Label className="mb-0" htmlFor="exclude_meter">{t('excludeMeter')}</Label>
                                                <InfoTooltip id="tooltip7" tooltipText={t('excludeMeterInfo')} />
                                            </div>
                                            <div style={{ border: '1px solid dodgerblue', borderRadius: '4px', padding: '10px' }}>
                                                <Row>
                                                    <Col md={6}>
                                                        <div>
                                                            <Label htmlFor='meter_start' className="form-label">
                                                                {t('partyStart')} | <span className='text-muted'>m</span>
                                                            </Label>
                                                            <Input
                                                                type="number"
                                                                className="form-control"
                                                                id='meter_start'
                                                                style={{ border: "1px solid #ced4da" }}
                                                                step="1"
                                                                min="0"
                                                                value={excludeStart || ''}
                                                                onChange={(e) => setExcludeStart(parseFloat(e.target.value) || null)}
                                                                onKeyDown={(e) => {
                                                                    if (e.key === "-" || e.key === "+" || e.key === "," || e.key === "." || e.key === "e") {
                                                                        e.preventDefault(); // Block these characters from entering
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </Col>

                                                    <Col md={6}>
                                                        <div>
                                                            <Label htmlFor='meter_end' className="form-label">
                                                                {t('partyEnd')} | <span className='text-muted'>m</span>
                                                            </Label>
                                                            <Input
                                                                type="number"
                                                                className="form-control"
                                                                id='meter_end'
                                                                style={{ border: "1px solid #ced4da" }}
                                                                step="1"
                                                                min="0"
                                                                value={excludeEnd || ''}
                                                                onChange={(e) => setExcludeEnd(parseFloat(e.target.value) || null)}
                                                                onKeyDown={(e) => {
                                                                    if (e.key === "-" || e.key === "+" || e.key === "," || e.key === "." || e.key === "e") {
                                                                        e.preventDefault(); // Block these characters from entering
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div> : <div />
                                    }

                                    {/* CYCLIC FILTER */}
                                    {(reportType?.value === 4) ?
                                        <div className='mt-3'>
                                            <div className="d-flex align-items-center">
                                                <Label className="mb-0" htmlFor="cyclic_flaw_meter">{t('cyclicFlawMeter')}</Label>
                                                <InfoTooltip id="tooltip6" tooltipText={t('cyclicFlawMeterInfo')} />
                                            </div>
                                            <Input
                                                type="number"
                                                className="form-control"
                                                id="cyclicFlawMeter"
                                                style={{ border: '1px solid dodgerblue', padding: '0.5rem' }}
                                                step="1"
                                                min="0"
                                                placeholder={t('cyclicFlawMeterInput')}
                                                value={cyclicFlawMeter || ''} // synchronize state value with input
                                                onChange={(e) => setCyclicFlawMeter(parseFloat(e.target.value) || null)}
                                                onKeyDown={(e) => {
                                                    if (e.key === "-" || e.key === "+" || e.key === "," || e.key === "." || e.key === "e") {
                                                        e.preventDefault(); // Block these characters from entering
                                                    }
                                                }}
                                            />
                                        </div> : <div />
                                    }

                                    {/* TIME RANGE FILTER */}
                                    <div className='d-flex justify-content-between align-items-end'>
                                        <div className="d-flex align-items-center mt-3">
                                            <Label className="mb-0" htmlFor="cyclic_flaw_meter">{t('dateRange')}</Label>
                                            <InfoTooltip id="tooltip8" tooltipText={t('dateRangeInfo')} />
                                        </div>
                                        <Button className='btn-sm' color='primary'
                                            onClick={() => {
                                                setStartDate(null);  // clear start date
                                                setEndDate(null);    // clear end date
                                            }}
                                        >{t('clear')}</Button>
                                    </div>
                                    <Row className='mt-1'>
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <DatePickerMaterialUI
                                                label={t('startDate')}
                                                handleSelect={handleDateSelect}
                                                func={setStartDate}
                                                type="start"
                                                date={startDate}
                                                key={reportType?.value}
                                            />
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <DatePickerMaterialUI
                                                label={t('endDate')}
                                                handleSelect={handleDateSelect}
                                                func={setEndDate}
                                                type="end"
                                                date={endDate}
                                                startDate={startDate}
                                                key={reportType?.value}
                                            />
                                        </Col>
                                    </Row>

                                    <Row className='mt-3'>
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <Button className='w-100' color='primary' type='submit'>{t('preview')}</Button>
                                        </Col>
                                        {(reportType?.value === 2) ?
                                            <div />
                                            :
                                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <Button className='w-100' color='success' type='button'
                                                    onClick={handleReport}>{t('excelReport')}</Button>
                                            </Col>
                                        }
                                    </Row>
                                </Form>



                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="9">
                        <Card>
                            <CardHeader className='pb-0'>
                                <h4 className="card-title mb-0">{t('reports')}</h4>
                            </CardHeader>
                            <hr></hr>
                            <CardBody className="mt-0 pt-0">
                                {
                                    reportType?.value === 2 &&
                                        flawType?.length === 1 &&
                                        startDate !== null &&
                                        endDate !== null && flawRange
                                        ? (
                                            <div className="mt-4">
                                                <LineChart flaws={flawRange} startDate={startDate} endDate={endDate} />
                                            </div>
                                        ) : (
                                            searchTable ? (
                                                <ReportTable
                                                    searchTable={searchTable}
                                                    columns={columns}
                                                    reportData={reportData}
                                                    pageSize={7}
                                                    changePage={changePage}
                                                    clearTable={clearTable}
                                                    t={t}
                                                />
                                            ) : (
                                                <div className="text-muted text-center" style={{
                                                    width: '100%',
                                                    paddingTop: '0',
                                                    fontSize: '24px',
                                                    fontWeight: 'bolder',
                                                }}>
                                                    {t('previewWarning')}
                                                </div>
                                            )
                                        )
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
};


export default ReportPanel;