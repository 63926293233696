// OrderDeleteMessage.js
import React from 'react';
import { useTranslation } from 'react-i18next';

const FlawDeleteMessage = ({ item }) => {
    const { t } = useTranslation('navbar');

    const message = t('flawDeleteMsg', { user: item.deletion_requested_by.username, partyNo: item.party_no, rollCount: item.roll_count});

    return (
        <span dangerouslySetInnerHTML={{ __html: message }} />
    );
};

const RollDeleteMessage = ({ item }) => {
    const { t } = useTranslation('navbar');

    const message = t('rollDeleteMsg', { user: item.deletion_requested_by.username, partyNo: item.party_no, rollCount: item.roll_count});

    return (
        <span dangerouslySetInnerHTML={{ __html: message }} />
    );
};

const OrderDeleteMessage = ({ item }) => {
    const { t } = useTranslation('navbar');

    const message = t('deleteMsg', { user: item.deletion_requested_by.username, partyNo: item.party_no });

    return (
        <span dangerouslySetInnerHTML={{ __html: message }} />
    );
};


const OtherNotificationFlawMessage = ({item}) => {
    const { t } = useTranslation('navbar');

    const message = t(
        'otherDeleteFlawMsg',
        { user: item.username, partyNo: item.party_no, rollCount: item.roll_count }
    );

    return (
        <span dangerouslySetInnerHTML={{ __html: message }} />
    );

}

const OtherNotificationRollMessage = ({item}) => {
    const { t } = useTranslation('navbar');

    const message = t(
        'otherDeleteRollMsg',
        { user: item.username, partyNo: item.party_no, rollCount: item.roll_count }
    );

    return (
        <span dangerouslySetInnerHTML={{ __html: message }} />
    );
}

const OtherNotificationOrderMessage = ({item}) => {
    const { t } = useTranslation('navbar');

    const message = t(
        'otherDeleteOrderMsg',
        { user: item.username, partyNo: item.party_no }
    );

    return (
        <span dangerouslySetInnerHTML={{ __html: message }} />
    );

}
export { OrderDeleteMessage, OtherNotificationFlawMessage, FlawDeleteMessage, RollDeleteMessage, OtherNotificationRollMessage, OtherNotificationOrderMessage };
