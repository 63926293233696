import React, { useEffect, useState } from 'react';
import { Button } from "reactstrap";

const FilterTable = (props) => {
    const { filterData, t, flawTypeInfo, selectedRow, setSelectedRow, fetchFilter } = props;
    const [data, setData] = useState(null);
    const [page, setPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);

    const handleRowClick = (id) => {
        setSelectedRow(prevSelectedRow => prevSelectedRow === id ? null : id);
    };

    const getFlawTypeNames = (codes) => {
        if (!codes) return "-";
        return codes.split(",").map(code => {
            const flaw = flawTypeInfo.find(item => item[0] == code.trim());
            return flaw ? flaw[1] : code;
        }).join(", ");
    };

    // Format Min ve Max flaw size
    const formatFlawSize = (flawSize, sizeKey) => {
        if (!flawSize || typeof flawSize !== "object") return "-";
        return Object.entries(flawSize).map(([key, value]) => {
            const flaw = flawTypeInfo.find(item => item[0] == key);
            const flawName = flaw ? flaw[1] : key;
            return <div key={key}>{`${flawName}: ${value[sizeKey + "_width"] ?? "-"} / ${value[sizeKey + "_height"] ?? "-"}`}</div>;
        });
    };

    const handlePage = () => {
        // handle page count
        const page = Math.ceil(filterData && filterData.count / 5);
        setPage(page);
    }

    const changePage = async (e, rotation) => {
        /**
         * I use this function to change page
         * I do not call all the data with recursive function like in the filter table
         * because the data may be too much and it takes too long to load
         */
        const url = filterData[rotation];  // get data according to rotation
        fetchFilter(url);
    };

    const handlePageChange = async (e, rotation) => {
        await changePage(e, rotation)
        if (rotation === 'next' && currentPage < page)
            setCurrentPage(currentPage + 1);
        else if (rotation === 'previous' && currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    }

    useEffect(() => {
        /**
        * if any of the data changes, this function will be called
        * and the data will be updated
        */
        setData(filterData);
    }, [filterData]);

    useEffect(() => {
        handlePage();
    }, [filterData])

    return (
        <div className="table-responsive table-card mt-0 mb-0 p-2">
            <div style={{ minHeight: "400px" }}>
                <table className="table table-nowrap table-striped-columns mb-0 text-center w-100"
                    style={{ boxShadow: "none", border: "1.6px solid rgba(128, 128, 128, 0.50)" }}>
                    <thead className="table-dark">
                        <tr className="table-nowrap align-content-center text-center">
                            <th className="border border-gray-400 px-4 py-2" style={{ whiteSpace: "pre-line" }}>{t('filterName')}</th>
                            <th className="border border-gray-400 px-4 py-2" style={{ whiteSpace: "pre-line" }}>{t('flawType')}</th>
                            <th className="border border-gray-400 px-4 py-2" style={{ whiteSpace: "pre-line" }}>{t('cyclicFlawMeter')}</th>
                            <th className="border border-gray-400 px-4 py-2" style={{ whiteSpace: "pre-line" }}>{t('excludeMeterStart')}</th>
                            <th className="border border-gray-400 px-4 py-2" style={{ whiteSpace: "pre-line" }}>{t('excludeMeterEnd')}</th>
                            <th className="border border-gray-400 px-4 py-2" style={{ whiteSpace: "pre-line" }}>{t('minFlawTypeSize')} <br /> {t('widthHeight')}</th>
                            <th className="border border-gray-400 px-4 py-2" style={{ whiteSpace: "pre-line" }}>{t('maxFlawTypeSize')} <br /> {t('widthHeight')}</th>
                        </tr>
                    </thead>
                    <tbody style={{ textAlign: "center" }}>
                        {
                            filterData && filterData.results && filterData.results.length > 0 ? (
                                filterData.results.map((data, index) => (
                                    <tr
                                        key={index + '-' + data.id}
                                        onClick={() => handleRowClick(data.id)}
                                        style={{
                                            cursor: "default",
                                            transition: "box-shadow 0.3s ease, background-color 0.3s ease",
                                            backgroundColor: selectedRow === data.id ? "rgba(0, 0, 0, 0.2)" : "transparent"
                                        }}
                                        className={`hover-row ${selectedRow === data.id ? "selected" : ""}`}
                                    >
                                        <td className="border border-gray-400 px-4 py-2" style={{ whiteSpace: "pre-line" }}>{data.name}</td>
                                        <td className="border border-gray-400 px-4 py-2" style={{ whiteSpace: "pre-line" }}>{getFlawTypeNames(data.flaw_type_code)}</td>
                                        <td className="border border-gray-400 px-4 py-2" style={{ whiteSpace: "pre-line" }}>{data.cycle_length !== null ? data.cycle_length : '-'}</td>
                                        <td className="border border-gray-400 px-4 py-2" style={{ whiteSpace: "pre-line" }}>{data.exclude_start !== null ? data.exclude_start : '-'}</td>
                                        <td className="border border-gray-400 px-4 py-2" style={{ whiteSpace: "pre-line" }}>{data.exclude_end !== null ? data.exclude_end : '-'}</td>

                                        {/* Min Flaw Size */}
                                        <td className="border border-gray-400 px-4 py-2">
                                            {formatFlawSize(data.min_flaw_size, "min")}
                                        </td>

                                        {/* Max Flaw Size */}
                                        <td className="border border-gray-400 px-4 py-2">
                                            {formatFlawSize(data.max_flaw_size, "max")}
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="7" className="border border-gray-400 px-4 py-2">
                                        {t('filtersNoticeMsg')}
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>
            <div className='pagination-container'>
                <ul className="pagination justify-content-center"
                    style={{
                        border: "1px solid rgba(128, 128, 128, 0.27)",
                        width: "200px",
                        padding: "inherit",
                        margin: "0.5rem 0.5rem",
                        marginBottom: "0rem",
                    }}>
                    <li className="page-item">
                        <Button
                            className="page-link"
                            style={{ borderRight: '1px solid rgba(128, 128, 128, 0.27)', borderRadius: '0' }}
                            onClick={(e) => handlePageChange(e, 'previous')}
                            disabled={data && data.previous ? false : true}>
                            {props.t('prev')}
                        </Button>
                    </li>
                    <li className="page-item">
                        <span className="page-link">
                            <b>{isNaN(currentPage) ? 1 : currentPage}</b> of <b>{isNaN(page) ? 1 : page}</b>
                        </span>
                    </li>
                    <li className="page-item">
                        <Button
                            className="page-link"
                            style={{ borderLeft: '1px solid rgba(128, 128, 128, 0.27)', borderRadius: '0' }}
                            onClick={(e) => handlePageChange(e, 'next')}
                            disabled={data && data.next ? false : true}
                        >{props.t('next')}
                        </Button>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default FilterTable;
